/*
 * @Descripttion: hooks1
 * @version: 2.2.2
 * @LastEditors: 邓亚鑫
 * @LastEditTime: 2022-04-07 10:17
 */
import { reactive, toRaw } from 'vue'
import { cmsMessage, cmsNotice } from './utils'

export const useCheckStateAndEvent = (enhancerMap = {}) => {
  const check = reactive({
    // 这里是选择审批人的数据
    indeterminate: false,
    checkedList: [],
    totalCheckedList: [],
    checkedMap: {},
    checkAll: false,
    searchLbwList: [], // 展示的审批人
    totalSearchList: [], // 全部的审批人，做个备份
    searchLbwVal: undefined,
    staffList: [],
    checkedListRight: [],
    checkType: 0 //0-审批人，1-特权用印人
  })
  const onCheckAllChange = e => {
    // 审批人全选回调

    if (e.target.checked) {
      check.checkedList = toRaw(check.searchLbwList).map(item => {
        check.checkedMap[item.value] = true
        return item.value
      })
      check.checkedListRight = toRaw(check.searchLbwList).map(item => {
        console.log('check.checkedListRight的值', item)
        check.checkedMap[item.value] = true
        return item
      })
    } else {
      check.checkedList = []
      check.checkedListRight = []
      check.searchLbwList.forEach(item => {
        console.log('check.searchLbwList', item)
        check.checkedMap[item.value] = false
        return item.value
      })
    }
    check.checkAll = e.target.checked
    check.indeterminate = check.checkAll ? false : check.checkedList.length ? true : false
    enhancerMap.onCheckAllChange && enhancerMap.onCheckAllChange()
  }
  const onChange = e => {
    // 审批人单个选择的回调
    // console.log("e",e.target);
    const target = e.target
    check.checkedMap[target.value] = target.checked
    const currtItem = check.totalSearchList.find(item => {
      return item.id === target.value
    })
    if (target.checked) {
      console.log('当前对象', currtItem)
      check.checkedListRight.push(currtItem)
      check.checkedMap[currtItem.value] && check.checkedList.push(currtItem.value)
    } else {
      check.checkedListRight.forEach((item, index) => {
        if (item.id == target.value) {
          check.checkedListRight.splice(index--, 1)
          // check.checkedList.splice(index--,1)
        }
      })
      check.checkedList.forEach((item, index) => {
        if (item == target.value) {
          check.checkedList.splice(index--, 1)
        }
      })
    }
    check.checkAll = check.checkedList.length === check.searchLbwList.length
    check.indeterminate = check.checkAll ? false : check.checkedList.length ? true : false
    enhancerMap.onChange && enhancerMap.onChange()
  }
  const reloadSearchLbwList = () => {
    // 重置选择审批人的列表
    const approvalStaff = toRaw(check.staffList)
    if (approvalStaff.length) {
      const approvalMap = {}
      approvalStaff.forEach(item => {
        approvalMap[item.id] = item
      })
      // check.searchLbwList = check.totalSearchList.filter(item => {
      //   return !approvalMap[item.id]
      // })
      check.checkedList = approvalStaff.map(item => {
        check.checkedMap[item.id] = approvalMap[item.id] ? true : false
        return item.id
      })
      check.searchLbwList = check.totalSearchList.map(item => item)
      check.checkedListRight = approvalStaff
    } else {
      check.searchLbwList = check.totalSearchList.map(item => item)
    }
  }
  const closeApprovaModel = () => {
    enhancerMap.closeApprovaModel && enhancerMap.closeApprovaModel()
    check.checkedList = []
    check.checkedListRight = []
    check.checkAll = false
    check.searchLbwVal = undefined
    reloadSearchLbwList()
  }
  const selectApprovalFn = () => {
    // 选择审批人，确定的回调
    const validateArr = []
    for (let key in check.checkedMap) {
      check.checkedMap[key] && validateArr.push(~~key)
    }
    // 判断选中的是否能为空
    // if (!validateArr.length) {
    //   cmsNotice('error', check.checkType?'请选择特权用印人':'请选择审批人')
    //   return
    // }
    enhancerMap.selectApprovalFn && enhancerMap.selectApprovalFn()
    const approvalMap = {}
    check.totalSearchList.forEach(item => {
      approvalMap[item.id] = item
    })
    // check.checkedList = validateArr
    // console.log(check.checkedList);
    // check.staffList = [...check.staffList, ...check.checkedList.map(item => approvalMap[item])]
    check.staffList = check.checkedList.map(item => approvalMap[item])
    enhancerMap.changeStaffList && enhancerMap.changeStaffList()
    check.indeterminate = false
    check.checkedList = []
    check.checkedListRight = []
    check.checkAll = false
    check.searchLbwVal = undefined
    reloadSearchLbwList()
  }

  const deleteApprovalFn = index => {
    // 删除选择的审批人
    const approvalMap = {}
    check.searchLbwList.forEach(item => {
      approvalMap[item.id] = item
    })
    check.staffList.splice(index, 1)
    // check.staffList = [...check.staffList, ...check.checkedList.map(item => approvalMap[item])]
    enhancerMap.changeStaffList && enhancerMap.changeStaffList()
    enhancerMap.deleteApprovalFn && enhancerMap.deleteApprovalFn()
    reloadSearchLbwList()
  }
  const searchApprovalList = () => {
    // 查找审批人
    reloadSearchLbwList()
    if (check.searchLbwVal) {
      if (enhancerMap.isSearchPhone) {
        const searchNameList = check.searchLbwList.filter(item => item.name.includes(check.searchLbwVal))
        const searchPhoneList = check.searchLbwList.filter(item => item.mobile.includes(check.searchLbwVal))
        check.searchLbwList = Array.from(new Set([...searchNameList, ...searchPhoneList])).map(item => item)
      } else {
        check.searchLbwList = check.searchLbwList.filter(item => item.name.includes(check.searchLbwVal))
      }
    }
    check.checkAll = check.searchLbwList.length === check.checkedList.length ? true : false
    check.indeterminate = check.checkAll ? false : check.checkedList.length ? true : false
    enhancerMap.searchApprovalList && enhancerMap.searchApprovalList()
  }

  const openApprovalModal = () => {
    console.log('openApprovalModal')
    enhancerMap.openApprovalModal && enhancerMap.openApprovalModal()
    // check.checkedMap = {}
    // check.checkedList = []
    // check.checkedListRight = []
    // check.searchLbwList.forEach(item => {
    //   check.checkedMap[item.value] = false
    // })
  }

  const initialApproalList = staffList => {
    console.log(staffList, 111)
    check.checkedList = []
    check.checkedListRight = []
    check.staffList = staffList || []
    check.checkAll = false
    check.searchLbwVal = undefined
    check.indeterminate = false
    reloadSearchLbwList()
  }

  // 右边列表点击事件
  const onChangeList = target => {
    check.checkedMap[target.id] = false
    check.checkedListRight.forEach((item, index) => {
      if (item.id == target.id) {
        check.checkedListRight.splice(index--, 1)
      }
    })
    check.checkedList = []
    check.checkedListRight.forEach(item => {
      check.checkedList.push(item.id)
    })
    enhancerMap.onChangeList && enhancerMap.onChangeList()
  }

  return {
    check,
    onChange,
    onCheckAllChange,
    reloadSearchLbwList,
    selectApprovalFn,
    closeApprovaModel,
    searchApprovalList,
    deleteApprovalFn,
    openApprovalModal,
    initialApproalList,
    onChangeList
  }
}
